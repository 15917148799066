export enum ContentTypes {
  ADVISOR = 'advisors',
  GUIDE = 'itineraries',
  BLOG = 'blogPosts',
  DESTINATION_CATEGORY = 'destinationsCategories',
  DESTINATION_SUBCATEGORY = 'destinationsSubcategories',
  TRAVEL_SUBCATEGORY = 'travelBySubcategories',
  PARTNER = 'entryPartner',
  TRIP_REPORT = 'entryTripReport',
}
export const ContentTypeLabels = {
  [ContentTypes.ADVISOR]: 'Advisor',
  [ContentTypes.GUIDE]: 'Guide',
  [ContentTypes.BLOG]: 'Article',
  [ContentTypes.DESTINATION_CATEGORY]: 'Destination',
  [ContentTypes.DESTINATION_SUBCATEGORY]: 'Destination',
  [ContentTypes.TRAVEL_SUBCATEGORY]: 'Category',
  [ContentTypes.PARTNER]: 'Partner',
  [ContentTypes.TRIP_REPORT]: 'Trip Report',
}
